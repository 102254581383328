<template>
  <app-overlay
    show
    @clicked="handleModalClose"
  >
    <app-modal
      class="bg-body"
      @closed="handleModalClose"
    >
      <font-awesome-icon
        icon="hourglass-end"
        size="5x"
        class="text-gray-300 mb-5"
      />

      <p class="text-sm mb-3">
        <strong>{{ t('auth.expiration.session_expired') }}</strong>
      </p>

      <login-form
        :additional-errors="formErrors"
        :submitting="formSubmitting"
        @submitted="handleSubmit"
      />
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import AppModal from '@shared/components/ui/AppModal.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import LoginForm from '@extranet/components/auth/login/LoginForm.vue'
import useLogin from '@shared/hooks/auth/login'

const { t } = useI18n()
const store = useStore()
const router = useRouter()

const {
  formSubmitting,
  formErrors,
  handleSubmit,
} = useLogin({
  noRedirectAfterLogin: true,
  noLoadingAfterLogin: true,
})

async function handleModalClose() {
  // Display loading screen
  store.commit('loading/SET_TEXT', t('auth.logout.logging_out'))
  store.commit('loading/ENABLE')
  await store.dispatch('auth/signOut')

  // Redirect to login page
  await router.push({ name: 'login' })
  store.commit('loading/RESET')
}
</script>

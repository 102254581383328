<template>
  <div class="mb-5 flex justify-between items-start md:items-center flex-col md:flex-row max-w-sm md:max-w-full">
    <!-- Search -->
    <form-group
      name="points_of_interest_search"
      :label="false"
      :form-control-props="{
        placeholder: t('points_of_interest.index.search'),
        iconProps: { library: 'coolicon', name: 'search', classes: ['form-icon-theme'], size: 'text-2xl' },
      }"
      class="max-w-sm w-full flex-1 mb-3 md:mb-0"
      @changed="handleSearch"
    />

    <!-- Create button -->
    <app-button
      v-if="store.state.auth.user.authorizations.points_of_interest.create"
      feature="create"
      :label="t('extranet.points_of_interest.actions.new')"
      class="w-full md:w-fit"
      @click="createStep"
    />
  </div>

  <!-- Filters -->
  <app-filters
    class="my-4"
    :display-filters="hasActiveFilters"
  >
    <point-of-interest-filters
      mode="claim"
      @filters-applied="handleFiltersApplied"
    />
  </app-filters>

  <!-- Results table -->
  <div>
    <app-table
      :headers="headers"
      :data="tableData"
      :loading="loading"
    >
      <template #colgroups>
        <colgroup>
          <col class="w-1/3">
          <col>
          <col>
        </colgroup>
      </template>
    </app-table>
  </div>

  <!-- Navigation buttons -->
  <div
    v-if="!loading"
    class="flex items-center justify-center mt-8"
  >
    <app-button
      v-if="previousPageAvailable"
      :label="t('pagination.previous_alt')"
      emphasis="low"
      @click="handlePreviousPage"
    />

    <span
      v-if="previousPageAvailable && nextPageAvailable"
      class="mx-4"
    >
      &bull;
    </span>

    <app-button
      v-if="nextPageAvailable"
      :label="t('pagination.next_alt')"
      emphasis="low"
      @click="handleNextPage"
    />
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { isNil } from 'lodash'

import { fetchPointsOfInterest } from '@shared/http/api'
import useLocale from '@shared/hooks/locale'
import useFilters from '@shared/hooks/filters'
import useForm from '@shared/hooks/form/form'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppFilters from '@extranet/components/ui/AppFilters.vue'
import AppTable from '@extranet/components/ui/table/AppTable.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import PointOfInterestFilters from '@extranet/components/resources/point_of_interest/PointOfInterestFilters.vue'
import PointOfInterestWithMainPicture from '@extranet/components/resources/point_of_interest/PointOfInterestWithMainPicture.vue'

const props = defineProps({
  // Handle poi select
  poiSelect: {
    type: Function,
    default: () => ({}),
  },
  // Handle create step
  createStep: {
    type: Function,
    default: () => ({}),
  },
})

const { t } = useI18n()
const store = useStore()

// ---------- POINTS OF INTEREST ----------

const { getAttributeTranslation } = useLocale()

const loading = ref(true)
const nextPageAvailable = ref(false)
const pointsOfInterest = ref([])
const previousPageAvailable = ref(false)

function fetchPois() {
  loading.value = true

  // Build params
  const params = {
    page: page.value,
    per_page: 5,
    search: searchKeywords.value,
    mode: 'index',
    source: 'extranet',
    filters: allFieldsToSubmit.value,
    order: allFieldsToSubmit.value.order,
  }

  fetchPointsOfInterest(params)
    .then((response) => {
      // Replace the old results with the new ones
      pointsOfInterest.value = response.data.data?.map((pointOfInterest) => ({
        resource: pointOfInterest,
      })) ?? []

      // Check if navigation available
      previousPageAvailable.value = !isNil(response.data.links?.prev)
      nextPageAvailable.value = !isNil(response.data.links?.next)
    })
    .finally(() => {
      loading.value = false
    })
}

// ---------- DATA TABLE ----------

const headers = [
  { label: t('validation.attributes.title') },
  { label: t('validation.attributes.category') },
  { label: t('validation.attributes.city') },
]

const tableData = computed(() => (
  pointsOfInterest?.value?.map((poi) => ({
    title: {
      component: PointOfInterestWithMainPicture,
      props: {
        resource: poi.resource,
        withModal: true,
        onClick: () => handlePoiSelected(poi.resource.id),
      },
    },
    category: getAttributeTranslation(poi.resource.relationships.category?.attributes?.label),
    city: getAttributeTranslation(poi.resource.relationships.address?.attributes?.city),
  }))
))

// ---------- FILTERS ----------

const { allFieldsToSubmit } = useForm(props, {
  initialValues: store.state.filters.pointsOfInterest,
  formName: 'pointsOfInterestIndexFiltersForm',
})

const { hasActiveFilters } = useFilters('pointsOfInterest')

function handleFiltersApplied() {
  resetPage()
  fetchPois()
}

// ---------- SEARCH ----------

const typingTimer = ref(0)
const searchKeywords = ref(null)

function handleSearch(value) {
  // Wait a little before emit the 'searched event'
  // it allows user to finish typing, and avoid to trigger an api call at each keystroke
  if (typingTimer.value) {
    clearTimeout(typingTimer.value)
  }
  typingTimer.value = setTimeout(() => {
    resetPage()
    searchKeywords.value = value
    fetchPois()
  }, 400)
}

// ---------- PAGINATION ----------

const page = ref(1)

function resetPage() {
  page.value = 1
}

function handlePreviousPage() {
  page.value -= 1
  fetchPois()
}

function handleNextPage() {
  page.value += 1
  fetchPois()
}

function handlePoiSelected(id) {
  props.poiSelect(id)
}

// ---------- LIFECYCLES ----------

onMounted(() => {
  // Filters' form-groups are rendered in child component
  // So must wait onMounted lifecycle hook to fetch resources with filters
  fetchPois()
})
</script>

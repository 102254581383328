const state = {
  open: false,
}

const mutations = {
  OPEN(state) {
    state.open = true
  },
  CLOSE(state) {
    state.open = false
  },
}

const actions = {
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

<template>
  <ol class="flex items-center w-full my-4 text-sm font-medium text-center text-gray-500 sm:text-base">
    <li
      v-for="step in steps"
      :key="step.step"
      :class="itemClasses(step.step)"
    >
      <span
        v-if="!isLastStep(step.step)"
        class="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 whitespace-nowrap"
      >
        <app-icon
          v-if="previousSteps(step.step)"
          name="circle-check"
          class="mr-1"
        />

        <span class="mr-1">
          {{ step.step }}
        </span>

        &middot;
        {{ step.name }}
      </span>

      <span
        v-else
        class="whitespace-nowrap"
      >
        <span>
          {{ step.step }}
        </span>

        &middot;
        {{ step.name }}
      </span>
    </li>
  </ol>
</template>

<script setup>
import AppIcon from '@shared/components/ui/AppIcon.vue'

const props = defineProps({
  // Array of step objects
  steps: {
    type: Array,
    default: () => [],
  },
  // Current step number
  currentStep: {
    type: Number,
    default: 1,
  },
})

function isCurrentStep(step) {
  return step === props.currentStep
}

function previousSteps(step) {
  return step < props.currentStep
}

function isLastStep(step) {
  return step === props.steps.length
}

function itemClasses(step) {
  let classes = [
    'flex',
    'items-center',
  ]

  if (isCurrentStep(step)) {
    classes = [
      ...classes,
      'text-blue-600',
      'after:border-blue-600',
    ]
  }

  if (!isLastStep(step)) {
    classes = [
      ...classes,
      'md:w-full',
      'after:content-[\'\']',
      'after:w-full',
      'after:border-b',
      'after:border-gray-200',
      'after:border-1',
      'after:hidden',
      'sm:after:inline-block',
      'after:mx-6',
      'xl:after:mx-10',
    ]
  }

  return classes.join(' ')
}
</script>

<template>
  <footer class="sticky bottom-0 p-4 w-full pointer-events-none">
    <div class="flex justify-end">
      <transition-group
        name="fade"
      >
        <button
          v-if="showButton"
          :key="1"
          class="bg-theme-500 w-10 h-10 flex items-center justify-center shadow-lg shadow-theme-500/10 rounded-full pointer-events-auto opacity-1 duration-100"
          @click="scrollToTop"
        >
          <font-awesome-icon
            icon="chevron-up"
            class="text-white"
          />
        </button>
      </transition-group>
    </div>
  </footer>
</template>

<script setup>
import {
  onBeforeUnmount,
  onMounted,
  ref,
} from 'vue'

const showButton = ref(false)

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

function handleScroll() {
  showButton.value = window.scrollY > 150
}

onMounted(() => {
  window.addEventListener(
    'scroll',
    handleScroll,
  )
})

onBeforeUnmount(() => {
  window.removeEventListener(
    'scroll',
    handleScroll,
  )
})
</script>

<style scoped lang="scss">
.fade-enter-from, .fade-leave-to {
  @apply opacity-0;
}
</style>

const state = {
  mobileOpen: false,
}

const mutations = {
  OPEN_MOBILE(state) {
    state.mobileOpen = true
  },
  CLOSE_MOBILE(state) {
    state.mobileOpen = false
  },
}

const actions = {
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

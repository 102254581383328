<template>
  <li :class="`${itemStyle}`">
    <router-link
      v-slot="{ navigate }"
      :to="{ name: routeName }"
    >
      <div
        class="flex items-center cursor-pointer w-fit"
        @click="() => handleClick(navigate)"
      >
        <app-icon
          v-bind="iconProps"
        />

        <span
          :class="`text-xl font-bold ml-4 ${displayText ? '' : 'lg:hidden'}`"
        >
          {{ text }}
        </span>
      </div>
    </router-link>
  </li>
</template>

<script setup>
import { computed } from 'vue'

import AppIcon from '@shared/components/ui/AppIcon.vue'

const props = defineProps({
  // Text displayed
  text: {
    type: String,
    required: true,
  },
  // Name of the vue route used
  routeName: {
    type: String,
    required: true,
  },
  // app-icon component props
  iconProps: {
    type: Object,
    required: true,
  },
  // Display text or not
  displayText: {
    type: Boolean,
    default: true,
  },
  // Reverse bg and font colors
  reversedStyle: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits([
  'clicked',
])

function handleClick(callback) {
  callback()
  emits('clicked')
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
}

// ---------- STYLE ----------

const itemStyle = computed(() => (
  props.reversedStyle ? ' bg-white text-theme-500' : ''
))
</script>

<template>
  <div class="flex justify-between mb-4">
    <p class="font-bold text-xl">
      {{ t('tutorials.extranet.begin.tourist_office_employee.steps.institution.subtitle.part_1') }}
    </p>

    <app-tutorial-button
      :tutorials="tutorials"
    />
  </div>

  <institutions-index
    :per-page="4"
    @selected="handleSelect"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import { applyPointOfInterest } from '@shared/http/api'
import AppTutorialButton from '@extranet/components/ui/help/AppTutorialButton.vue'
import HelpInstitutionTouristOffice from '@extranet/components/begin/help/HelpInstitutionTouristOffice.vue'
import InstitutionsIndex from '@extranet/components/resources/institution_employee/request_form/institutions/InstitutionsIndex.vue'

const emits = defineEmits([
  'submitted',
])

// ---------- TUTORIALS ----------

const { t } = useI18n()

const tutorials = computed(() => ([
  {
    menuItem: t('tutorials.extranet.begin.hotelier.steps.employees.title'),
    component: HelpInstitutionTouristOffice,
  },
]))

// ---------- SELECTED ----------

const store = useStore()

function handleSelect(resource) {
  store.commit('spinner/ENABLE')

  applyPointOfInterest(resource.id)
    .then(() => {
      store.commit(
        'flashes/ADD_FLASH',
        {
          message: t('institutions_employees.apply.success'),
          type: 'success',
        },
      )

      handleRefresh()
    })
    .finally(() => {
      store.commit('spinner/DISABLE')
    })
}

// ---------- REFRESH MODAL ----------

async function handleRefresh() {
  await store.dispatch('auth/getAuthenticatedUser')
    .then(() => {
      emits('submitted')
    })
}
</script>

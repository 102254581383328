<template>
  <app-overlay
    show
    @clicked="handleModalClose"
  >
    <app-modal
      max-width-classes="max-w-screen-2xl"
      text-align-classes="text-left"
      :loading="pointOfInterestModalLoading"
      class="h-screen"
      @closed="handleModalClose"
    >
      <point-of-interest-show
        v-if="pointOfInterestModalResource"
        :resource="pointOfInterestModalResource"
        :with-actions="false"
      />
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'

import { fetchPointOfInterest as apiFetchPointOfInterest } from '@shared/http/api'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import PointOfInterestShow from '@extranet/components/resources/point_of_interest/PointOfInterestShow.vue'

const store = useStore()

const pointOfInterestModalResource = ref()
const pointOfInterestModalDisplayed = ref(false)
const pointOfInterestModalLoading = ref(false)

function handleModalClose() {
  store.commit('modals/RESET', 'point_of_interest_show')
}

function fetchPointOfInterest() {
  const id = store.state.modals.point_of_interest_show.id

  pointOfInterestModalDisplayed.value = true
  pointOfInterestModalLoading.value = true

  apiFetchPointOfInterest(id)
    .then((response) => {
      pointOfInterestModalResource.value = response.data.data
    })
    .finally(() => {
      pointOfInterestModalLoading.value = false
    })
}

// ---------- LIFECYCLE ----------

onMounted(() => {
  fetchPointOfInterest()
})
</script>

<template>
  <li>
    <div
      class="pl-4 py-2 hover:text-white hover:bg-theme-400 rounded-r-full cursor-pointer"
      @click="handleClick"
    >
      <font-awesome-icon
        v-if="iconName"
        class="mr-1"
        :icon="[iconStyle, iconName]"
      />

      {{ label }}
    </div>
  </li>
</template>

<script setup>
defineProps({
  // Text displayed
  label: {
    type: String,
    required: true,
  },
  // Name of the fontawesome
  // icon used
  iconName: {
    type: String,
    default: '',
  },
  // Style of the fontawesome
  // icon used
  iconStyle: {
    type: String,
    default: 'fas',
  },
})

const emits = defineEmits([
  'clicked',
])

function handleClick() {
  emits('clicked')
}
</script>

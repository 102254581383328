<template>
  <div
    v-if="isPaired(mainRole)"
    class="flex justify-center mt-4"
  >
    <app-button
      v-if="!minimized"
      emphasis="medium"
      :label="buttonLabel"
      class="max-w-full bg-white"
      font-size="sm"
      @click="handleLogout"
    />

    <app-button
      v-else
      emphasis="medium"
      :icon-props="{
        library: 'fontawesome',
        name: 'power-off',
        size: '1x',
        classes: ['flex'],
      }"
      :hide-text="true"
      :hide-icon="false"
      class="max-w-full bg-white"
      @click="handleLogout"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import useLogout from '@shared/hooks/auth/logout'
import useRegisteringContexts from '@shared/hooks/registeringContexts'
import AppButton from '@shared/components/ui/AppButton.vue'

defineProps({
  // Display in minimized
  minimized: {
    type: Boolean,
    default: false,
  },
})

const store = useStore()
const { t } = useI18n()

// ---------- LOGOUT ----------

const { logout } = useLogout({
  auth_route_name: 'login',
})

async function handleLogout() {
  logout()
}

// ---------- CONTEXTS ----------

const mainRole = computed(() => (
  store.state.auth.user.attributes.roles[0]
))

const pairedRole = computed(() => (
  getRoleName(getPairedRole(mainRole.value))
))

// ---------- LABEL ----------

const buttonLabel = computed(() => (
  `${t('auth.logout.switch')} ${pairedRole.value}`
))

const {
  getPairedRole,
  getRoleName,
  isPaired,
} = useRegisteringContexts()
</script>

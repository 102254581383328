<template>
  <p class="font-bold text-xl mb-4">
    {{ t('tutorials.extranet.begin.shared.claims.documents.title') }}
  </p>

  <p>
    {{ t('tutorials.extranet.begin.shared.claims.documents.description') }}
  </p>

  <p class="mt-4 font-medium">
    {{ t('tutorials.extranet.begin.shared.claims.documents.company.title') }}
  </p>

  <ul class="list-disc list-inside">
    <li>
      {{ t('tutorials.extranet.begin.shared.claims.documents.company.kbis') }}
    </li>
  </ul>

  <p class="mt-4 font-medium">
    {{ t('tutorials.extranet.begin.shared.claims.documents.individual.title') }}
  </p>

  <ul class="list-disc list-inside">
    <li>
      {{ t('tutorials.extranet.begin.shared.claims.documents.individual.registration_document') }}
    </li>
  </ul>

  <p class="mt-4 font-medium">
    {{ t('tutorials.extranet.begin.shared.claims.documents.association.title') }}
  </p>

  <ul class="list-disc list-inside">
    <li>
      {{ t('tutorials.extranet.begin.shared.claims.documents.association.statutes') }}
    </li>
  </ul>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

import router from '@shared/store/modules/router'
import flashes from '@shared/store/modules/flashes'
import auth from '@shared/store/modules/auth'
import loading from '@shared/store/modules/loading'
import filters from '@shared/store/modules/filters'
import resources from '@shared/store/modules/resources'
import terms from '@shared/store/modules/terms'
import locales from '@shared/store/modules/locales'
import modals from '@shared/store/modules/modals'
import spinner from '@shared/store/modules/spinner'

import navbar from '@extranet/store/modules/navbar'
import dropdown from '@extranet/store/modules/dropdown'

export default {
  state: {
    appName: 'extranet',
  },
  modules: {
    router,
    flashes,
    auth,
    loading,
    filters,
    resources,
    terms,
    spinner,
    navbar,
    dropdown,
    locales,
    modals,
  },
}

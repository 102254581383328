<template>
  <ol class="space-y-4">
    <stepper-item
      v-for="step in steps"
      :key="step.stepNumber"
      v-bind="step"
      :active="isActive(step)"
      :done="isDone(step)"
    />
  </ol>
</template>

<script setup>
import StepperItem from '@extranet/components/tutorials/stepper/StepperItem.vue'

const props = defineProps({
  // Array of step objects
  steps: {
    type: Array,
    default: () => [],
  },
  // Current active step
  activeStep: {
    type: Number,
    default: 0,
  },
})

// ---------- STATE ----------

function isActive(step) {
  return props.activeStep === step.stepNumber
}

function isDone(step) {
  return step.stepNumber < props.activeStep
}
</script>

<template>
  <app-overlay :show="show">
    <app-modal
      :key="componentKey"
      max-width-classes="max-w-screen-xl"
      text-align-classes="text-left"
      @closed="toggleBeginModal"
    >
      <!-- Display begin modal depending on permissions -->
      <component
        :is="currentComponent"
        v-if="currentComponent"
        @submitted="forceRefresh"
      >
        <template #remindMeLater>
          <div class="flex justify-center">
            <app-button
              type="button"
              :label="t('common.actions.remind_me_later')"
              class="sm:mt-4 sm:py-2 sm:px-4 w-max px-4 py-2"
              @click="handleCloseModal"
            />
          </div>
        </template>
      </component>
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { inject, ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { updateLastSkipBeginModal } from '@shared/http/api'
import useAccess from '@extranet/hooks/access'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import BeginButler from '@extranet/components/begin/BeginButler.vue'
import BeginHotelier from '@extranet/components/begin/BeginHotelier.vue'
import BeginManager from '@extranet/components/begin/BeginManager.vue'
import BeginTouristOffice from '@extranet/components/begin/BeginTouristOffice.vue'
import BeginTouristOfficeEmployee from '@extranet/components/begin/BeginTouristOfficeEmployee.vue'

const { t } = useI18n()

defineProps({
  // Display modal
  show: {
    type: Boolean,
    default: false,
  },
})

// ---------- MODAL ----------

const { toggleBeginModal } = inject('isBeginModalOpen')

const handleCloseModal = () => {
  updateLastSkipBeginModal().then(() => {
    toggleBeginModal()
  })
}

// ---------- ACCESS ----------

const {
  dashboardHotelierAccess,
  dashboardManagerAccess,
  dashboardButlerAccess,
  dashboardTouristOfficeAccess,
  dashboardTouristOfficeEmployeeAccess,
} = useAccess()

// ---------- COMPONENT REFRESH ----------

const componentKey = ref(0)

// Force tabs component to refresh
function forceRefresh() {
  componentKey.value += 1
}

const currentComponent = computed(() => {
  if (dashboardHotelierAccess) {
    return BeginHotelier
  }
  if (dashboardManagerAccess) {
    return BeginManager
  }
  if (dashboardButlerAccess) {
    return BeginButler
  }
  if (dashboardTouristOfficeAccess) {
    return BeginTouristOffice
  }
  if (dashboardTouristOfficeEmployeeAccess) {
    return BeginTouristOfficeEmployee
  }
  return null
})
</script>

<template>
  <div
    v-if="show"
    ref="userDropdown"
    class="fixed z-50 w-60 right-0 top-14 bg-white shadow-lg shadow-theme-500/10"
  >
    <div class="px-4 pt-4">
      <p class="font-semibold">
        {{ userDisplayName }}
      </p>

      <p class="text-sm">
        {{ authUserRoles }}
      </p>
    </div>

    <ul
      id="user-dropdown"
      class="pr-4 py-4"
    >
      <dropdown-item
        :label="t('extranet.user_dropdown.profile')"
        icon-name="user-circle"
        class="text-sm"
        @clicked="showPage('show-profile')"
      />

      <dropdown-item
        v-if="canManageSubscription"
        :label="t('extranet.user_dropdown.subscription')"
        icon-name="gem"
        class="text-sm"
        @clicked="showPage('subscriptions')"
      />

      <dropdown-item
        :label="t('extranet.user_dropdown.cookies')"
        icon-name="cookie"
        class="text-sm"
        @clicked="showPage('show-cookies')"
      />

      <dropdown-item
        :label="t('extranet.user_dropdown.privacy_policy')"
        icon-name="info-circle"
        class="text-sm"
        @clicked="showPage('index-privacy-policy')"
      />

      <dropdown-item
        :label="t('extranet.user_dropdown.terms_condition')"
        icon-name="info-circle"
        class="text-sm"
        @clicked="showPage('index-terms-condition')"
      />

      <dropdown-item
        :label="t('extranet.user_dropdown.partners')"
        icon-name="handshake"
        icon-style="far"
        class="text-sm"
        @clicked="showPage('index-partners')"
      />

      <dropdown-item
        :label="t('extranet.user_dropdown.customers')"
        icon-name="handshake"
        icon-style="far"
        class="text-sm"
        @clicked="showPage('index-customers')"
      />

      <dropdown-item
        :label="t('extranet.user_dropdown.credits')"
        icon-name="copyright"
        icon-style="far"
        class="text-sm"
        @clicked="showPage('index-credits')"
      />

      <dropdown-item
        :label="t('extranet.user_dropdown.sign_out')"
        icon-name="sign-out-alt"
        class="text-sm text-red-500 font-semibold"
        @clicked="handleLogout"
      />
    </ul>
  </div>
</template>

<script setup>
import {
  computed,
  onBeforeUnmount,
  onMounted,
  ref,
} from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import useUser from '@shared/hooks/resources/user'
import useLogout from '@shared/hooks/auth/logout'
import DropdownItem from './DropdownItem.vue'

defineEmits([
  'closed',
])

const store = useStore()
const router = useRouter()
const { t } = useI18n()

const {
  roles: authUserRoles,
  displayName: userDisplayName,
} = useUser({ resource: store.state.auth.user })

// ---------- DROPDOWN ----------

const userDropdown = ref(null)

const show = computed(() => (
  store.state.dropdown.open
))

function handleClose() {
  store.commit('dropdown/CLOSE')
}

function handleClick(e) {
  const userButton = document.querySelector('#userDropdown')

  if (userDropdown.value
    && !userDropdown.value?.contains(e.target)
    && !userButton.contains(e.target)
  ) {
    handleClose()
  }
}

// ---------- SUBSCRIPTION ----------

const canManageSubscription = computed(() => (
  store.state.auth.user.attributes.can_manage_subscriptions
))

// ---------- LOGOUT ----------

const { logout } = useLogout({
  auth_route_name: 'login',
})

async function handleLogout() {
  handleClose()
  logout()
}

// ---------- SHOW PAGE ----------

function showPage(route) {
  handleClose()
  router.push({ name: route })
}

// ---------- LIFECYCLES ----------

onMounted(() => {
  window.addEventListener(
    'click',
    handleClick,
  )
})

onBeforeUnmount(() => {
  window.removeEventListener(
    'click',
    handleClick,
  )
})
</script>

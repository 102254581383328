import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import useAccess from '@extranet/hooks/access'

export default function useNavbar() {
  const { t } = useI18n()

  const {
    institutionsAccess,
    institutionAccess,
    employeesAccess,
    excursionsAccess,
    travellersAccess,
    travellerEmailsAccess,
    invitationsAccess,
  } = useAccess()

  const navItems = computed(() => {
    const items = []

    // Dashboard
    items.push({
      routeName: 'dashboard',
      text: t('extranet.navbar.dashboard'),
      iconProps: {
        library: 'coolicon',
        name: 'bar_chart_circle',
      },
    })

    // Institution
    // (for employees)
    if (institutionAccess.value) {
      items.push({
        routeName: 'institutions',
        text: t('extranet.navbar.my_institution'),
        iconProps: {
          library: 'coolicon',
          name: 'building',
        },
      })
    }

    // Institutions
    // (for managers)
    if (institutionsAccess.value) {
      items.push({
        routeName: 'institutions',
        text: t('extranet.navbar.my_institutions'),
        iconProps: {
          library: 'coolicon',
          name: 'building',
        },
      })
    }

    // Employees
    if (employeesAccess.value) {
      items.push({
        routeName: 'index-institutions-employees',
        text: t('extranet.navbar.my_employees'),
        iconProps: {
          library: 'fontawesome',
          name: 'user-tie',
          class: 'ml-1',
        },
      })
    }

    // Travellers
    if (travellersAccess.value) {
      items.push({
        routeName: 'travellers',
        text: t('extranet.navbar.travellers'),
        iconProps: {
          library: 'coolicon',
          name: 'user',
        },
      })
    }

    // Traveller emails
    if (travellerEmailsAccess.value) {
      items.push({
        routeName: 'traveller-emails',
        text: t('extranet.navbar.travellers'),
        iconProps: {
          library: 'coolicon',
          name: 'user',
        },
      })
    }

    // Excursions
    if (excursionsAccess.value) {
      items.push({
        routeName: 'index-excursions',
        text: t('extranet.navbar.excursions'),
        iconProps: {
          library: 'coolicon',
          name: 'map',
        },
      })
    }

    // POI
    items.push({
      routeName: 'index-points-of-interest',
      text: t('extranet.navbar.points_of_interest'),
      iconProps: {
        library: 'coolicon',
        name: 'location_outline',
      },
    })

    // Finances
    items.push({
      routeName: 'finances',
      text: t('extranet.navbar.finances'),
      iconProps: {
        library: 'coolicon',
        name: 'line_chart_up',
      },
    })

    // Invitations
    if (invitationsAccess.value) {
      items.push({
        routeName: 'invitations',
        text: t('extranet.navbar.invitations'),
        iconProps: {
          library: 'coolicon',
          name: 'message',
        },
      })
    }

    // FAQ
    items.push({
      routeName: 'faq',
      text: t('extranet.navbar.faq'),
      iconProps: {
        library: 'coolicon',
        name: 'help_circle_outline',
      },
    })

    // Contact
    items.push({
      routeName: 'index-support-requests',
      text: t('extranet.navbar.contact'),
      iconProps: {
        library: 'coolicon',
        name: 'chat',
      },
    })

    return items
  })

  return {
    navItems,
  }
}

// Keep in store some resources, fetched from API,
// for when they need to be accessed across components without making additional API requests

const state = {
  pointsOfInterest: [],
}

const mutations = {
  GET_RESOURCE(state, payload) {
    // Which state's key will be searched in
    const type = payload.type

    // Retrieve resource by its id
    return state[type]?.find((resource) => (
      resource.id === payload.id
    ))
  },

  ADD_RESOURCES(state, payload) {
    // Which state's key will be searched in
    const type = payload.type
    const newResources = payload.resources

    newResources?.forEach((newResource) => {
      // Check if it is already in store
      const oldResource = state[type]?.find((resource) => (
        resource.id === payload.id
      ))

      if (oldResource) {
        // Update the old resource, by completing the attributes and relationships,
        // so if new attributes are provided by the API, they're added, and the old ones are not lost
        oldResource.attributes = {
          ...oldResource.attributes,
          ...newResource.attributes,
        }
        oldResource.relationships = {
          ...oldResource.relationships,
          ...newResource.relationships,
        }
      } else {
        // Add the new resource
        state[type]?.push(newResource)
      }
    })
  },
}

const actions = {
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

<template>
  <li :class="textColor">
    <div :class="`w-full p-4 border ${borderColor} rounded-lg`">
      <div class="flex items-center justify-between">
        <span :class="`flex items-center justify-center w-8 h-8 border ${borderColor} rounded-full shrink-0 mr-4`">
          {{ step }}
        </span>

        <span class="w-full text-left">
          <h3 class="font-medium leading-tight">
            {{ stepTitle }}
          </h3>

          <p class="text-sm">
            {{ stepDescription }}
          </p>
        </span>

        <app-icon
          v-if="showIcon"
          library="coolicon"
          :name="iconName"
        />
      </div>
    </div>
  </li>
</template>

<script setup>
import { computed } from 'vue'

import AppIcon from '@shared/components/ui/AppIcon.vue'

const props = defineProps({
  // Step number
  stepNumber: {
    type: Number,
    required: true,
  },
  // Step state
  stepState: {
    type: String,
    required: true,
  },
  // Step title
  stepTitle: {
    type: String,
    required: true,
  },
  // Step description
  stepDescription: {
    type: String,
    required: true,
  },
  // Is step done?
  done: {
    type: Boolean,
    default: false,
  },
  // Is step active?
  active: {
    type: Boolean,
    default: false,
  },
})

// ---------- STYLE ----------

const textColor = computed(() => {
  let color = 'text-gray-500'

  if (props.done) color = 'text-green-700'
  if (props.active) color = 'text-theme-700'

  return color
})

const borderColor = computed(() => {
  let color = 'border-gray-300'

  if (props.done) color = 'border-green-500'
  if (props.active) color = 'border-theme-500'

  return color
})

// ---------- ICON ----------

const showIcon = computed(() => (
  props.active
    || props.done
))

const iconName = computed(() => (
  props.done
    ? 'check_big'
    : 'long_right'
))

// ---------- NUMBER ----------

const step = computed(() => (
  props.stepNumber + 1
))
</script>

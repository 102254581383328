<template>
  <div
    class="flex justify-center items-center h-screen"
  >
    <div
      class="flex flex-col items-start space-y-3 sm:flex-row sm:space-y-0 sm:items-center sm:space-x-3"
    >
      <p
        class="font-semibold text-theme-500 text-9xl"
      >
        403
      </p>

      <div
        class="space-y-2"
      >
        <h1
          id="pageTitle"
          class="flex items-center space-x-2"
        >
          <font-awesome-icon
            icon="exclamation-triangle"
            size="lg"
          />

          <span
            class="text-xl font-medium text-gray-600 sm:text-2xl"
          >
            {{ t('exceptions.error_texts.403.title') }}
          </span>
        </h1>

        <p
          class="text-base font-normal text-gray-600"
        >
          {{ t('exceptions.error_texts.403.message') }}
        </p>

        <p
          v-if="store.state.auth?.user?.authorizations?.access?.app"
          class="text-base font-normal text-gray-600"
        >
          {{ t('exceptions.error_texts.403.link.title') }}

          <a
            :href="appUrl"
            class="app-link"
          >
            {{ t('exceptions.error_texts.403.link.to_app_label') }}
          </a>
        </p>

        <app-button
          feature="logout"
          :hide-icon="false"
          emphasis="low"
          class="mt-5"
          @click="logout"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import AppButton from '@shared/components/ui/AppButton.vue'
import useLogout from '@shared/hooks/auth/logout'

const store = useStore()
const { t } = useI18n()
const { logout } = useLogout({
  auth_route_name: 'login',
})

const appUrl = computed(() => (
  import.meta.env.VITE_TRAVELLER_APP_URL
))

</script>

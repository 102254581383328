<template>
  <begin-modal
    :show="isBeginModalOpen"
    @closed="toggleBeginModal"
  />
</template>

<script setup>
import { inject } from 'vue'

import BeginModal from '@extranet/components/begin/BeginModal.vue'

const { isBeginModalOpen, toggleBeginModal } = inject('isBeginModalOpen')
</script>
